import '@/assets/css/main.css'

import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import smoothscroll from 'smoothscroll-polyfill'

import App from './App.vue'
import router from './router'
import store from './store'
import Collapser from './directives/collapse/collapser'
import Collapsible from './directives/collapse/collapsible'

smoothscroll.polyfill()

Vue.use(VueAnalytics, {
  id: 'UA-28546731-1',
  router
})

Vue.directive('collapser', Collapser)
Vue.directive('collapsible', Collapsible)

// kickoff
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
