<template>
  <div>
    <div
      id="collapse"
      class="collapse"/>

    <transition name="fade">
      <div
        v-show="collapsed"
        class="collapse-undo link link--no-active"
        @click.prevent="handleClick"/>
    </transition>
  </div>
</template>

<script>
import p5 from 'p5'
import Matter from 'matter-js'

export default {
  name: 'Collapse',

  data () {
    return {
      p5: null,
      collapsibles: null,
      collapsed: false
    }
  },

  mounted () {
    this.collapsibles = document.querySelectorAll('.collapsible')
    this.$nextTick(this.collapse)
    this.countdown()
  },

  beforeDestroy () {
    this.p5.destroy()
    this.p5.remove()
    this.p5 = null
    this.destroyCollapsibles()
  },

  methods: {
    collapse () {
      const vm = this

      // eslint-disable-next-line new-cap
      this.p5 = new p5(sketch => {
        let engine
        let world
        let canvas
        let mouseConstraint

        let wallBodies = []
        let boxBodies = []

        // make walls thick enough to prevent collision detection issues
        // https://github.com/liabru/matter-js/issues/5
        const wallThickness = 700

        // create distance between edges of the canvas to prevent scrollbars
        const wallGutter = 15

        sketch.setup = function () {
          canvas = sketch.createCanvas(document.body.clientWidth, document.body.scrollHeight)
          engine = Matter.Engine.create()
          world = engine.world

          sketch.rectMode(sketch.CENTER)
          sketch.noStroke()
          sketch.noFill()

          const mouse = Matter.Mouse.create(canvas.elt)
          const mouseParams = { mouse }

          mouseConstraint = Matter.MouseConstraint.create(engine, mouseParams)
          mouseConstraint.mouse.pixelRatio = sketch.pixelDensity()

          // allow for scrolling
          mouseConstraint.mouse.element.removeEventListener('mousewheel', mouse.mousewheel)
          mouseConstraint.mouse.element.removeEventListener('DOMMouseScroll', mouse.mousewheel)

          Matter.World.add(world, mouseConstraint)

          const wallParams = { isStatic: true }

          wallBodies = [
            // top
            Matter.Bodies.rectangle(
              sketch.width / 2, -(wallThickness / 2) + wallGutter,
              sketch.width, wallThickness,
              wallParams
            ),
            // right
            Matter.Bodies.rectangle(
              sketch.width + (wallThickness / 2) - wallGutter, sketch.height / 2,
              wallThickness, sketch.height,
              wallParams
            ),
            // bottom
            Matter.Bodies.rectangle(
              sketch.width / 2, sketch.height + (wallThickness / 2) - wallGutter,
              sketch.width, wallThickness,
              wallParams
            ),
            // left
            Matter.Bodies.rectangle(
              -(wallThickness / 2) + wallGutter, sketch.height / 2,
              wallThickness, sketch.height,
              wallParams
            )
          ]

          wallBodies.forEach(body => Matter.World.add(world, body))

          vm.collapsibles.forEach(box => {
            const offsetX = box.offsetLeft + (box.clientWidth / 2)
            const offsetY = box.offsetTop + (box.clientHeight / 2)
            const width = box.clientWidth
            const height = box.clientHeight
            const boxBody = Matter.Bodies.rectangle(offsetX, offsetY, width, height)
            boxBodies.push(boxBody)
          })

          Matter.World.add(world, boxBodies)
          Matter.Engine.run(engine)
        }

        sketch.draw = function () {
          vm.collapsibles.forEach((box, i) => {
            const width = box.clientWidth
            const height = box.clientHeight
            const boxBody = boxBodies[i]
            const pos = boxBody.position
            const offsetX = Math.floor(pos.x - (width / 2) - box.offsetLeft)
            const offsetY = Math.floor(pos.y - (height / 2) - box.offsetTop)
            const angle = boxBody.angle
            const degrees = angle * 180 / Math.PI

            box.style.transform = `translate3d(${offsetX}px, ${offsetY}px, 0) rotate(${degrees}deg)`

            sketch.push()
            sketch.translate(pos.x, pos.y)
            sketch.rotate(angle)
            sketch.rect(0, 0, width, height)
            sketch.pop()
          })

          wallBodies.forEach(body => {
            const pos = body.position
            const bounds = body.bounds
            const width = Math.abs(bounds.max.x - bounds.min.x)
            const height = Math.abs(bounds.max.y - bounds.min.y)

            sketch.push()
            sketch.translate(pos.x, pos.y)
            sketch.rect(0, 0, width, height)
            sketch.pop()
          })
        }

        sketch.windowResized = function () {
          const width = sketch.width
          const height = sketch.height

          sketch.resizeCanvas(sketch.windowWidth, document.body.scrollHeight)

          wallBodies.forEach(function (body, i) {
            const xVertices = [
              { x: 0, y: 0 },
              { x: width, y: 0 },
              { x: width, y: wallThickness },
              { x: 0, y: wallThickness }
            ]
            const yVertices = [
              { x: 0, y: 0 },
              { x: wallThickness, y: 0 },
              { x: wallThickness, y: height },
              { x: 0, y: height }
            ]

            switch (i) {
              case 0:
                // top
                Matter.Body.setPosition(body, { x: width / 2, y: -(wallThickness / 2) + wallGutter })
                Matter.Body.setVertices(body, xVertices)
                break
              case 1:
                // right
                Matter.Body.setPosition(body, { x: width + (wallThickness / 2) - wallGutter, y: height / 2 })
                Matter.Body.setVertices(body, yVertices)
                break
              case 2:
                // bottom
                Matter.Body.setPosition(body, { x: width / 2, y: height + (wallThickness / 2) - wallGutter })
                Matter.Body.setVertices(body, xVertices)
                break
              case 3:
                // left
                Matter.Body.setPosition(body, { x: -(wallThickness / 2) + wallGutter, y: height / 2 })
                Matter.Body.setVertices(body, yVertices)
                break
            }
          })

          vm.collapsibles.forEach((box, i) => {
            const width = box.clientWidth
            const height = box.clientHeight
            const boxBody = boxBodies[i]

            const vertices = [
              { x: 0, y: 0 },
              { x: width, y: 0 },
              { x: width, y: height },
              { x: 0, y: height }
            ]

            Matter.Body.setVertices(boxBody, vertices)
          })
        }

        sketch.destroy = function () {
          wallBodies.forEach(body => Matter.Composite.remove(world, body))
          boxBodies.forEach(body => Matter.Composite.remove(world, body))

          Matter.World.clear(world)
          Matter.Engine.clear(engine)

          canvas = null
          world = null
          engine = null
          canvas = null
        }
      }, 'collapse')
    },

    countdown () {
      setTimeout(() => { this.collapsed = true }, 3000)
    },

    handleClick () {
      if (this.$store.state.collapsed) {
        this.$store.dispatch('toggleCollapse')
      }
    },

    destroyCollapsibles () {
      const resetStyles = function () {
        this.style.transition = ''
        this.style.transform = ''
      }

      this.collapsed = false

      this.collapsibles.forEach(collapsible => {
        collapsible.addEventListener('webkitTransitionEnd', resetStyles, { once: true })
        collapsible.addEventListener('transitionend', resetStyles, { once: true })
        collapsible.style.transition = 'transform 0.75s ease'
        collapsible.style.transform = 'translate3d(0, 0, 0) rotate(0deg)'
      })
    }
  }
}
</script>

<style>
.collapse {
  @apply absolute z-4;
  left: 0;
  top: 0;
}

.collapse canvas {
  @apply block;
}

.collapse-undo {
  @apply text-lg leading-tight z-5 m-sm;
  display: inline-block;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate(25%, -5%);
}
.collapse-undo::after {
  content: '✨';
  display: inline;
}

@screen md {
  .collapse-undo {
    @apply text-xl my-lg mx-xl;
  }
}
</style>
