import store from '@/store'

function click () {
  store.dispatch('toggleCollapse')
}

export default {
  bind (el) {
    el.addEventListener('click', click)
  },

  unbind (el) {
    el.removeEventListener('click', click)
  }
}
