function addClass (el) {
  if (!el.classList.contains('collapsible')) {
    el.classList.add('collapsible')
  }
}

export default {
  bind (el) { addClass(el) },
  componentUpdated (el) { addClass(el) }
}
