import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import Misc from './views/Misc.vue'
import Projects from './views/Projects.vue'
import store from './store.js'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-exact-active',

  routes: [
    {
      path: '/information',
      name: 'information',
      components: { misc: Misc },
      props: {
        misc: {
          page_title: 'Information',
          page_slug: 'information'
        }
      }
    },
    {
      path: '/contact',
      name: 'contact',
      components: { misc: Misc },
      props: {
        misc: {
          page_title: 'Contact',
          page_slug: 'contact'
        }
      }
    },
    {
      path: '/:category_slug/:project_slug?',
      name: 'projects',
      components: { projects: Projects }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (Object.keys(store.state.pages).length) {
    next()
  } else {
    store.dispatch('getPages').then(next)
  }
})

export default router
