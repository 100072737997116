<template>
  <div
    :class="classObject"
    class="app">

    <header class="heading pb-md md:pb-2xl">
      <h2
        v-collapsible
        class="inline-block">Erik Carter</h2>
      <div
        v-collapsible
        class="heading__text">
        <p>Graphic Designer</p>
      </div>
    </header>

    <section class="section pb-md md:pb-2xl">
      <header class="heading">
        <router-link
          v-collapsible
          v-for="(category, i) in $store.state.pages.categories"
          v-if="$route.params.category_slug !== category.slug"
          :to="{ name: 'projects', params: { category_slug: category.slug, project_slug: category.first } }"
          :key="category.slug"
          class="inline-block">
          <span class="link">{{ category.title }}</span><template v-if="i + 1 < $store.state.pages.categories.length">,&nbsp;</template>
        </router-link>
      </header>

      <div id="projects">
        <expandable :scroll-to="'#projects'">
          <router-view name="projects"/>
        </expandable>
      </div>
    </section>

    <section class="section pb-md md:pb-2xl">
      <header class="heading">
        <router-link
          v-collapsible
          v-if="$route.path !== '/information'"
          to="/information"
          class="inline-block">
          <span class="link">Information</span>,&nbsp;
        </router-link>

        <router-link
          v-collapsible
          v-if="$route.path !== '/contact'"
          to="/contact"
          class="inline-block">
          <span class="link">Contact</span>,&nbsp;
        </router-link>

        <span
          v-collapsible
          v-collapser
          class="inline-block link">
          Collapse
        </span>
      </header>

      <div id="misc">
        <expandable :scroll-to="'#misc'">
          <router-view name="misc"/>
        </expandable>
      </div>
    </section>

    <collapse v-if="$store.state.collapsed"/>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import EventBus from '@/event-bus'
import Expandable from './components/Expandable.vue'
import Collapse from './components/Collapse.vue'

export default {
  name: 'App',

  metaInfo: {
    titleTemplate: title => {
      return title ? `${title} | Erik Carter` : 'Erik Carter'
    }
  },

  components: {
    Expandable,
    Collapse
  },

  data () {
    return {
      ready: false
    }
  },

  computed: {
    classObject () {
      return {
        'is-ready': this.ready,
        'is-collapsed': this.$store.state.collapsed
      }
    }
  },

  async beforeCreate () {
    await this.$store.dispatch('getPages')
    this.$nextTick(() => { this.ready = true })
  },

  created () {
    window.addEventListener('resize', this.resize)
    window.addEventListener('orientationchange', this.resize)
    this.resize()
  },

  destroyed () {
    window.removeEventListener('resize')
    window.removeEventListener('orientationchange')
  },

  methods: {
    resize: debounce(function () {
      EventBus.$emit('resize', {
        width: window.innerWidth,
        height: window.innerHeight
      })
    }, 250)
  }
}
</script>

<style>
.app {
  @apply min-h-full p-sm;
  opacity: 0;
}
.app.is-ready {
  animation: fade 0.25s ease-in-out forwards;
}

@screen md {
  .app {
    @apply py-lg px-xl;
  }
}
</style>
