import Vue from 'vue'
import Vuex from 'vuex'
import api from './api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pages: {},
    categories: {},
    headerHeight: 0,
    loading: false,
    collapsed: false,
    timers: {}
  },

  getters: {
    category: (state) => (slug) => {
      if (slug) {
        return state.pages.categories.find(category => category.slug === slug)
      } else {
        return null
      }
    },

    project: (state, getters) => ({ categorySlug, projectSlug }) => {
      if (!categorySlug || !projectSlug) return null
      const projects = state.categories[categorySlug]
      if (projects) {
        return projects.find(project => project.slug === projectSlug)
      } else {
        return null
      }
    }
  },

  mutations: {
    SET_PAGES (state, data) { Vue.set(state, 'pages', data) },
    SET_PAGE (state, { page, data }) { Vue.set(state.pages[page], 'data', data) },
    SET_HEADER_HEIGHT (state, height) { state.headerHeight = height },
    TIMER_START (state, { timer, delay, cb }) {
      if (!state.timers[timer]) state.timers[timer] = setTimeout(cb, delay)
    },
    TIMER_STOP (state, timer) {
      if (state.timers[timer]) {
        clearTimeout(state.timers[timer])
        state.timers[timer] = null
      }
    },
    LOAD_START (state) { state.loading = true },
    LOAD_STOP (state) { state.loading = false },
    COLLAPSE (state) { state.collapsed = true },
    UNCOLLAPSE (state) { state.collapsed = false }
  },

  actions: {
    async getPages ({ state, commit }) {
      // try the store first
      if (Object.keys(state.pages).length) return state.pages

      const { data } = await api().get()
      commit('SET_PAGES', data)
      return data
    },

    async getPage ({ state, commit }, page) {
      // try the store first
      const pageData = state.pages[page].data
      if (pageData) return pageData

      const { data } = await api().get(page)
      commit('SET_PAGE', { page, data })
      return data
    },

    async getCategoryProjects ({ state }, slug) {
      // try the store first
      const category = state.categories[slug]
      if (category) return category

      const { data } = await api().get(slug)
      Vue.set(state.categories, slug, data)
      return data
    },

    startLoading ({ commit }) {
      commit('TIMER_STOP', 'loading')
      commit('TIMER_START', {
        timer: 'loading',
        delay: 1500,
        cb () {
          commit('LOAD_START')
        }
      })
    },

    stopLoading ({ commit }) {
      commit('TIMER_STOP', 'loading')
      commit('TIMER_START', {
        timer: 'loading',
        delay: 500,
        cb () {
          commit('LOAD_STOP')
        }
      })
    },

    toggleCollapse ({ state, commit }) {
      const collapsed = state.collapsed
      if (collapsed) {
        commit('UNCOLLAPSE')
      } else {
        commit('COLLAPSE')
      }
      return !collapsed
    }
  }
})
