<template>
  <div class="text-sm flex flex-wrap">
    <div
      v-if="info"
      class="flex-auto flex-no-shrink min-w-280 w-1/3 pb-1 sm:pr-lg">
      <div
        v-collapsible
        class="rich-text mb-1"
        v-html="info"/>
    </div>

    <div
      v-if="links"
      class="flex-auto flex-no-shrink min-w-280 w-1/3 pb-1 sm:pr-lg">
      <div
        v-for="link in links"
        :key="link.title"
        class="rich-text mb-1">
        <h4
          v-collapsible
          class="inline-block">{{ link.title }}</h4>
        <div
          v-collapsible
          class="rich-text"
          v-html="link.text"/>
      </div>
    </div>

    <div
      v-if="copyright || credits"
      class="flex-auto flex-no-shrink min-w-280 w-1/3 pb-1 sm:pr-lg">
      <div
        v-collapsible
        v-if="copyright"
        class="rich-text mb-1"
        v-html="copyright"/>

      <div
        v-collapsible
        v-if="credits"
        class="rich-text mb-1"
        v-html="credits"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Information',

  data () {
    return {
      info: this.$store.state.pages.information.info,
      links: this.$store.state.pages.information.links,
      copyright: this.$store.state.pages.information.copyright,
      credits: this.$store.state.pages.information.credits
    }
  },

  mounted () {
    this.$nextTick(() => { this.$emit('initialized') })
  }
}
</script>
