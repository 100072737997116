<template>
  <div class="loader">
    <div class="loader__spinner">
      <svg viewBox="25 25 50 50">
        <circle
          class="loader__path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke="#000"
          stroke-width="5"
          stroke-miterlimit="10"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',

  computed: {
    active () {
      return this.$store.state.loading
    }
  }
}
</script>

<style>
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-path {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

.loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}

.loader__spinner {
  animation: spin 4s linear infinite;
  height: 60px;
  width: 60px;
  transform-origin: center center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -30px;
  margin-top: -30px;
}

.loader__path {
  animation: spin-path 1.5s ease-in-out infinite;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
}

</style>
